import axios from 'axios';
import ConstApiUrls from "@/services/const-api-urls";
import authHeaderMultipart from "@/services/auth-header-multipart"

const API_URL = ConstApiUrls.API_SERVICE_URL

class FileUploadService {

    uploadImage(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post(API_URL +"storage/upload_image", formData, {
            headers: authHeaderMultipart() ,
            onUploadProgress
        });
    }

    uploadDocument(file, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);

        return axios.post(API_URL +"storage/upload_file", formData, {
            headers: authHeaderMultipart() ,
            onUploadProgress
        });
    }


}

export default new FileUploadService();